import { apiUrl } from 'config';
import { PersistanceService } from '@app/services';
import { BaseResponseInterface } from '@app/types';


type MethodType = 'GET' | 'POST' | 'POST-FILE' | 'PUT' | 'DELETE';

type ContentType = 'application/json';

type HeadersType = {
  'Content-Type'?: ContentType;
  'Authorization'?: string;
}

type OptionsType = {
  method: MethodType;
  headers: HeadersType;
  body: any;
};


export class HttpService {
  private static getBody(
    method: MethodType,
    payload: any,
  ): any {
    if (method === 'GET' || method === 'DELETE') {
      return null;
    }

    if (method === 'POST-FILE') {
      return payload;
    }

    return JSON.stringify(payload);
  }

  private static getHeaders(
    method: MethodType,
  ): HeadersType {
    const token = PersistanceService.getToken();

    const headers: HeadersType = {
      'Authorization': token,
    };

    if (method !== 'POST-FILE') {
      headers['Content-Type'] = 'application/json';
    }

    return headers;
  }

  private static correctMethod(
    method: MethodType,
  ): MethodType {
    if (method === 'POST-FILE') {
      return 'POST';
    }

    return method;
  }

  private static getOptions(
    method: MethodType,
    payload: any,
  ): OptionsType {
    const options = {
      method: this.correctMethod(method),
      headers: this.getHeaders(method),
      body: this.getBody(method, payload),
    };

    return options;
  };

  private static getParams(method: MethodType, payload: any): string {
    if ((method === 'POST' || method === 'POST-FILE' || method === 'PUT' ) || typeof payload !== 'object') {
      return '';
    }
    
    const keys = Object.keys(payload);
    let params = '?';

    keys.forEach((key) => {
      if (payload[key]) {
        params += key + '=' + payload[key] + '&';
      }
    });

    if (params[params.length - 1] === '&') {
      params = params.slice(0, -1);
    }

    return params;
  }

  public static async sendRequest(
    method: MethodType,
    url: string,
    payload?: any
  ): Promise<BaseResponseInterface<any>> {
    const options = this.getOptions(method, payload);
    const params = this.getParams(method, payload);

    try {
      const response = await fetch(apiUrl + url + params, options);

      const data = JSON.parse(await response.text())

      if (data.errors || (data.message && (data.statusCode && (data.statusCode !== 200)))) {
        return {
          success: false,
          errors: data.errors || data.message,
          data: null,
        }
      }

      return { data: { ...data }, success: true };
    } catch (error) {
      console.error(`${method}: ${url} - Failed to fetch`, error)
      return {
        success: false,
        data: null,
        errors: { server: [`${method}: ${apiUrl + url} - Failed to fetch`] },
      };
    }
  };
}
